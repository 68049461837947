import { Route } from '@angular/router';
import { ecommerceGuard } from '@core/guards/ecommerce.guard';
import { hasConnectedEcommerceGuard } from '@core/guards/has-connected-ecommerce.guard';
import { PRODUCT_FORMAT_TYPE } from '@features/inbox/types/catalog.types';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { MAIN_SLUGS, PRODUCTS_SLUGS } from '@routes/routes.types';

export const PRODUCT_ROUTE: Route = {
    path: MAIN_SLUGS.PRODUCTS,
    loadComponent: () =>
        import('@features/products/products.component').then(m => m.ProductsComponent),
    canActivate: [subscriptionGuard],
    children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: PRODUCTS_SLUGS.catalog,
        },
        {
            path: PRODUCTS_SLUGS.catalog,
            title: 'Catalog',
            loadComponent: () =>
                import('@features/products/prods-catalog/prods-catalog.component').then(
                    m => m.ProdsCatalogComponent
                ),
            canActivate: [ecommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.promotions,
            title: 'Promotions',
            loadComponent: () =>
                import('@features/products/prods-promotions/prods-promotions.component').then(
                    m => m.ProdsPromotionsComponent
                ),
        },
        {
            path: PRODUCTS_SLUGS.discounts,
            title: 'Discounts',
            loadComponent: () =>
                import('@features/products/prods-discounts/prods-discounts.component').then(
                    m => m.ProdsDiscountsComponent
                ),
            canActivate: [ecommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.landing,
            title: 'Connect your store',
            loadComponent: () =>
                import('@features/products/products-landing/products-landing.component').then(
                    m => m.ProductsLandingComponent
                ),
            canActivate: [hasConnectedEcommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.setup,
            title: 'Setup your store',
            loadComponent: () =>
                import(
                    '@features/products/products-initial-config/products-initial-config.component'
                ).then(m => m.ProductsInitialConfigComponent),
            canActivate: [hasConnectedEcommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.chooseBulkSingle,
            title: 'Add product',
            loadComponent: () =>
                import('@features/products/choose-bulk-single/choose-bulk-single.component').then(
                    m => m.ChooseBulkSingleComponent
                ),
            canActivate: [hasConnectedEcommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.chooseDigitalPhysical,
            title: 'Select product type',
            loadComponent: () =>
                import(
                    '@features/products/choose-digital-physical/choose-digital-physical.component'
                ).then(m => m.ChooseDigitalPhysicalComponent),
            canActivate: [hasConnectedEcommerceGuard],
        },
        {
            path: PRODUCTS_SLUGS.addBulkProducts,
            title: 'Bulk importer',
            loadComponent: () =>
                import(
                    '@features/products/products-bulk-importer/products-bulk-importer.component'
                ).then(m => m.ProductsBulkImporterComponent),
        },
        {
            path: PRODUCTS_SLUGS.addDigitalProduct,
            title: 'Add single product',
            data: { type: PRODUCT_FORMAT_TYPE.digital },
            loadComponent: () =>
                import('@features/products/product-form/product-form.component').then(
                    m => m.ProductFormComponent
                ),
        },
        {
            path: PRODUCTS_SLUGS.addPhysicalProduct,
            title: 'Add single product',
            data: { type: PRODUCT_FORMAT_TYPE.physical },
            loadComponent: () =>
                import('@features/products/product-form/product-form.component').then(
                    m => m.ProductFormComponent
                ),
        },
        {
            path: PRODUCTS_SLUGS.form,
            title: 'Form product',
            loadComponent: () =>
                import('@features/products/product-form/product-form.component').then(
                    m => m.ProductFormComponent
                ),
        },
        {
            path: PRODUCTS_SLUGS.connect,
            title: 'Select platform',
            pathMatch: 'full',
            loadComponent: () =>
                import(
                    '@features/products/prods-select-platform/prods-select-platform.component'
                ).then(m => m.ProdsSelectPlatformComponent),
        },
        {
            path: PRODUCTS_SLUGS.connect,
            children: [
                {
                    path: PRODUCTS_SLUGS.shopify,
                    title: 'Connect Shopify',
                    pathMatch: 'full',
                    loadComponent: () =>
                        import(
                            '@features/products/prods-sync-shopify/prods-sync-shopify.component'
                        ).then(m => m.ProdsSyncShopifyComponent),
                },
                {
                    path: PRODUCTS_SLUGS.magento,
                    title: 'Connect Magento',
                    pathMatch: 'full',
                    loadComponent: () =>
                        import(
                            '@features/products/prods-sync-magento/prods-sync-magento.component'
                        ).then(m => m.ProdsSyncMagentoComponent),
                },
                {
                    path: PRODUCTS_SLUGS.woocommerce,
                    title: 'Connect WooCommerce',
                    pathMatch: 'full',
                    loadComponent: () =>
                        import(
                            '@features/products/prods-sync-woo-commerce/prods-sync-woo-commerce.component'
                        ).then(m => m.ProdsSyncWooCommerceComponent),
                },
            ],
        },
        {
            path: PRODUCTS_SLUGS.categories,
            title: 'Categories',
            loadComponent: () =>
                import('@features/products/prods-categories/prods-categories.component').then(
                    m => m.ProdsCategoriesComponent
                ),
            canActivate: [ecommerceGuard],
        },
    ],
};
