import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { META_ACCOUNT_STATUS } from '@core/models/admin/connect-meta.types';
import { FbAccountService } from '@core/services/admin/fb-account.service';
import { InstagramService } from '@core/services/admin/instagram.service';
import { AUTH_SLUGS } from '@features/auth/auth.routes';
import { APP_SLUGS, MAIN_SLUGS, SETTINGS_SLUGS } from '@routes/routes.types';
import { ToastrService } from 'ngx-toastr';

export const metaReconnectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const igS = inject(InstagramService);
    const fbS = inject(FbAccountService);
    const toastr = inject(ToastrService);

    const reconnectPage = `/${APP_SLUGS.MAIN}/${MAIN_SLUGS.SETTINGS}/${SETTINGS_SLUGS.thirdParty}/${SETTINGS_SLUGS.meta}`;
    const loginPage = `/${APP_SLUGS.AUTH}/${AUTH_SLUGS.login}`;
    const allowed = [reconnectPage, loginPage];

    effect(() => {
        if ((igS.mustReconnect() || fbS.mustReconnect()) && !allowed.includes(state.url)) {
            toastr.error(
                'Required to continue using the app',
                'Please reconnect your Meta account'
            );
            router.navigateByUrl(reconnectPage);
        }
    });

    return true;
};
