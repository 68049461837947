import { Route, UrlSegment } from '@angular/router';
import { paymentSyncGuard } from '@core/guards/payment/payment-sync.guard';
import { paymentGuard } from '@core/guards/payment/payment.guard';
import { WITHOUT_GROWTH_PLAN_ROUTE } from '@features/settings/billing/billing.routes';
import { subscriptionLevelGuard } from '@features/settings/billing/guards/subscription-level.guard';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { MAIN_SLUGS, PAYMENT_FORM_SLUGS, PAYMENT_SLUG_SYNC, PAYMENT_SLUGS } from './routes.types';

const matcher = (allowed: string[]) => (url: UrlSegment[]) => {
    const slug = url.at(-1);
    if (slug && allowed.includes(slug.path)) {
        return { consumed: url, posParams: { slug: slug! } };
    } else {
        return null;
    }
};

export const PAYMENT_ROUTE: Route = {
    path: MAIN_SLUGS.PAYMENT,
    canActivate: [subscriptionGuard],
    children: [
        {
            path: '',
            redirectTo: PAYMENT_SLUGS.DASHBOARD,
            pathMatch: 'full',
        },
        {
            path: PAYMENT_SLUG_SYNC.sync,
            canActivate: [paymentSyncGuard, subscriptionLevelGuard],
            loadComponent: () =>
                import('@features/payments/pay-sync/pay-sync.component').then(
                    m => m.PaySyncComponent
                ),
        },
        WITHOUT_GROWTH_PLAN_ROUTE,
        {
            matcher: matcher(Object.values(PAYMENT_SLUGS)),
            canActivate: [paymentGuard],
            loadComponent: () =>
                import('@features/payments/payment.component').then(m => m.PaymentComponent),
        },
        {
            matcher: matcher(Object.values(PAYMENT_FORM_SLUGS)),
            canActivate: [paymentGuard],
            loadComponent: () =>
                import('@features/payments/pay-forms/pay-forms.component').then(
                    m => m.PayFormsComponent
                ),
        },
    ],
};
