import { computed, effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '@core/services/admin/account.service';
import { APP_SLUGS, MAIN_SLUGS, PAYMENT_SLUGS } from '@routes/routes.types';

export const paymentSyncGuard: CanActivateFn = (route, state) => {
    const accountS = inject(AccountService);
    const router = inject(Router);

    const hasStripeAccount = computed(() => accountS.account()?.stripeAccount?.stripe_account_id);

    effect(() => {
        if (hasStripeAccount()) {
            router.navigate([
                APP_SLUGS.MAIN,
                MAIN_SLUGS.audience,
                MAIN_SLUGS.PAYMENT,
                PAYMENT_SLUGS.DASHBOARD,
            ]);
        }
    });
    return true;
};
