import { computed, effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EcommerceAccountService } from '@core/services/admin/ecommerce-account.service';
import { APP_SLUGS, MAIN_SLUGS, PRODUCTS_SLUGS } from '@routes/routes.types';

export const hasConnectedEcommerceGuard: CanActivateFn = (route, state) => {
    const ecommerceAccountS = inject(EcommerceAccountService);
    const router = inject(Router);

    const hasPlatform = computed(() => ecommerceAccountS.account()?.platform);

    effect(() => {
        if (hasPlatform()) {
            router.navigate([
                APP_SLUGS.MAIN,
                MAIN_SLUGS.audience,
                MAIN_SLUGS.PRODUCTS,
                PRODUCTS_SLUGS.catalog,
            ]);
        }
    });

    return true;
};
