<div
    [ngClass]="{
        'border-twSuccessGreen bg-twLightGreen': toastPackage.toastType === 'toast-success',
        'border-twElectricPurple bg-twLightPurple': toastPackage.toastType === 'toast-info',
        'border-twClassicOrange bg-twLightOrange': toastPackage.toastType === 'toast-warning',
        'border-twErrorRed bg-twBgErrorRed': toastPackage.toastType === 'toast-error',
        'bg-twMainBackground': !toastPackage.toastType,
    }"
    class="relative inset-0 h-full w-full overflow-hidden rounded-card border p-[18px] shadow-toastr transition-all hover:shadow-toastrHover">
    <div class="row flex items-center gap-4">
        <!-- ICON -->
        @if (toastPackage.toastType === 'toast-success') {
            <app-square-checkmark class="text-twSuccessGreen" />
        }
        @if (toastPackage.toastType === 'toast-info') {
            <app-warning-hex class="size-5 rotate-180 text-twElectricPurple" />
        }
        @if (toastPackage.toastType === 'toast-warning') {
            <app-warning-hex class="size-5 text-twClassicOrange" />
        }
        @if (toastPackage.toastType === 'toast-error') {
            <app-cross-circle-inverse-icon class="block size-5 text-twErrorRed" />
        }
        <!-- TITLE -->
        <div class="col-9">
            <h3 class="font-medium text-twTextPrimary" [attr.aria-label]="title">
                {{ getTitle() }}
            </h3>
            <!-- TEXT -->
            @if (message && options.enableHtml) {
                <p class="text-twGrayDark" role="alert" [innerHTML]="message"></p>
            }
            @if (message && !options.enableHtml) {
                <p class="text-twGrayDark" role="alert" [attr.aria-label]="message">
                    {{ message }}
                </p>
            }
        </div>
    </div>

    @if (options.progressBar) {
        <div>
            <div class="toast-progress" [style.width]="width + '%'"></div>
        </div>
    }
</div>
