import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@core/services/admin/user.service';
import { APP_SLUGS } from '@routes/routes.types';
import { filter, map, take } from 'rxjs';

export const settingsAdminGuard: CanActivateFn = () => {
    const userS = inject(UserService);
    const router = inject(Router);

    return toObservable(userS.user).pipe(
        filter(user => user !== undefined && user !== null), // Skip initial undefined state
        take(1),
        map(user => !!user?.admin || router.parseUrl(`/${APP_SLUGS.MAIN}`))
    );
};
