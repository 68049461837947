import TEXT from '@content/core.en.json';
import { MAIN_SLUGS } from '@routes/routes.types';
import { InsightsIconComponent } from '@shared/components/icons/insights-icon/insights-icon.component';
import { AudienceIconComponent } from '@shared/components/icons/audience-icon/audience-icon.component';
import { AutomationIconComponent } from '@shared/components/icons/automation-icon.component';
import { GridPostsIconComponent } from '@shared/components/icons/grid-posts-icon/grid-posts-icon.component';
import { InboxIconComponent } from '@shared/components/icons/inbox-icon/inbox-icon.component';
import { CampaignsIconComponent } from '@shared/components/icons/campaigns-icon/campaigns-icon.component';
import { PaymentIconComponent } from '@shared/components/icons/payment-icon/payment-icon.component';
import { ProductsIconComponent } from '@shared/components/icons/products-icon/products-icon.component';
import { GearIconComponent } from '@shared/components/icons/gear-icon/gear-icon.component';
import { LikeIconComponent } from '@shared/components/icons/like-icon/like-icon.component';
import { AutomateRayComponent } from '@shared/components/icons/automate-ray/automate-ray.component';

export const SIDEBAR_OPTS = [
    {
        title: TEXT.sidebar.dashboard,
        icon: GridPostsIconComponent,
        link: MAIN_SLUGS.DASHBOARD,
    },
    {
        title: TEXT.sidebar.engage,
        icon: InboxIconComponent,
        link: MAIN_SLUGS.inbox,
    },
    {
        title: TEXT.sidebar.commerce,
        icon: AudienceIconComponent,
        link: MAIN_SLUGS.audience,
    },
    {
        title: TEXT.sidebar.reach,
        icon: CampaignsIconComponent,
        link: MAIN_SLUGS.campaigns,
    },
    {
        title: TEXT.sidebar.automate,
        icon: AutomateRayComponent,
        link: MAIN_SLUGS.automate,
    },
    {
        title: TEXT.sidebar.settings,
        icon: GearIconComponent,
        link: MAIN_SLUGS.SETTINGS,
    },
];
