import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-automate-ray',
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M10.9614 1.69621C11.1873 1.82217 11.2983 2.08597 11.2302 2.33552L9.73647 7.81252H15.1875C15.4115 7.81252 15.6141 7.94541 15.7034 8.15084C15.7927 8.35626 15.7516 8.59508 15.5987 8.75882L7.72373 17.1963C7.54724 17.3854 7.26453 17.4298 7.03861 17.3038C6.81268 17.1779 6.70177 16.9141 6.76983 16.6645L8.26356 11.1875H2.81251C2.58853 11.1875 2.38589 11.0546 2.29662 10.8492C2.20735 10.6438 2.24846 10.405 2.40129 10.2412L10.2763 1.80372C10.4528 1.61462 10.7355 1.57026 10.9614 1.69621Z"
                fill-rule="evenodd" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomateRayComponent {}
